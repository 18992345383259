
export default {
	name: "ProjectList",
	data() {
		return {
			id: "",
			token: "",
			HospitalList: [],
			loading: false,
			value: [],
			list: [],
			options: [],
			tableData: [],
			multipleSelection: [],
			name: "",
			search: "",
			crcName: "",
			projectCrcList: [],
			page: 1,
			data: {}
		};
	},

	mounted() { },
	created() {
		this.token = this.getCookie("token");
		this.id = this.$route.params.id;
		this.name = this.$route.params.name;
		if (this.token && this.id) {
			this.getHospitalList();
		}
	},

	methods: {
		// 获取医院列表
		getHospitalList() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/selectHospitalList", {
					hospitalName: ""
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.HospitalList = res.data.data;
						_this.list = _this.HospitalList.map(item => {
							return {
								value: item.id,
								label: item.name
							};
						});
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		getCrcList() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/selectCRCByHostoryId", {
					hostoryId: _this.value,
					name: _this.crcName,
					page: _this.page,
					projectId: _this.id
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.data = res.data.data
						var arr = res.data.data.objArray
						// for(var i=0;i<arr.length;i++){
						// 	if(!arr[i].isShow){
						// 		arr.splice(i,1)
						// 		i--
						// 	}
						// }
						_this.tableData = arr;
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		remoteMethod(query) {
			if (query !== "") {
				setTimeout(() => {
					this.options = this.list.filter(item => {
						return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
					});
				}, 200);
			} else {
				this.options = [];
			}
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
			this.projectCrcList = []
			for (var i = 0; i < val.length; i++) {
				this.projectCrcList.push({
					projectId: this.id,
					crcUserId: val[i].id
				})
			}
		},
		getSelect() {
			this.getCrcList();
		},
		confirm() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/saveProjectCRC", {
					projectCrcList: _this.projectCrcList
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.$message({
							type: "success",
							message: "操作成功!"
						});
						_this.toggleSelection()
						_this.getCrcList()
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		handleCurrentChange(val) {
			console.log(val)
			this.page = val;
			this.getCrcList()
		}
	}
};
